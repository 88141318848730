<template>
  <layout-loader-view></layout-loader-view>
</template>

<script>
import {mapMutations, mapActions} from "vuex";

export default {
  name: "CheckLogin",
  async mounted() {
    this.setLoader({visible: true, message: 'Authenticating'})

    const authenticated = await this.isLogged()

    const {$route, $router} = this

    if (authenticated) {
      this.setPassedUser(true)
      $router.push($route.query.to)
    } else {
      $router.push('/')
      this.setPassedUser(false)
    }
  },
  methods: {
    ...mapMutations('components', ['setLoader']),
    ...mapMutations('authentication', ['setPassedUser']),
    ...mapActions('authentication', ['isLogged'])
  }
}
</script>
